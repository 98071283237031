import Vue from 'vue'
import VueRouter from 'vue-router'
import SearchPage from '../views/search.vue'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:lang',
    component: {
      render(h) {
        return h("router-view");
      }
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
        meta: {
          title: 'Home'
        }
      },
      {
        path: 'out',
        name: 'Out',
        component: () => import(/* webpackChunkName: "home" */ '../views/outOfBuisness.vue'),
        meta: {
          title: 'Out'
        }
      },
      {
        path: 'yalidine',
        name: 'Yalidine',
        component: () => import(/* webpackChunkName: "yalidine" */ '../views/yalidinePage.vue'),
        meta: {
          title: 'Yalidine'
        }
      },
      {
        path: 'editOrder',
        name: 'EditOrder',
        component: () => import(/* webpackChunkName: "editOrder" */ '../views/editOrder.vue'),
        meta: {
          title: 'Edit Order'
        }
      },
      {
        path: 'delivery',
        name: 'Delivery',
        component: () => import(/* webpackChunkName: "delivery" */ '../views/deliveryPage.vue'),
        meta: {
          title: 'Delivery'
        }
      },
      {
        path: 'deliveryView',
        name: 'DeliveryDetails',
        component: () => import(/* webpackChunkName: "deliveryDetails" */ '../views/OrderView.vue'),
        meta: {
          title: 'Order Information'
        }
      },
      {
        path: 'store',
        name: 'Store',
        component: () => import(/* webpackChunkName: "store" */ '../views/StorePage.vue'),
        meta: {
          title: 'Store'
        }
      },
      {
        path: 'blackList',
        name: 'Black List',
        component: () => import(/* webpackChunkName: "blackList" */ '../views/blackList.vue'),
        meta: {
          title: 'Black List'
        }
      },
      {
        path: 'wishList',
        name: 'Wish List',
        component: () => import(/* webpackChunkName: "wishList" */ '../views/wishList.vue'),
        meta: {
          title: 'Wish List'
        }
      },
      {
        path: 'manageCoupons',
        name: 'Manage Coupons',
        component: () => import(/* webpackChunkName: "manageCoupons" */ '../views/manageCoupons.vue'),
        meta: {
          title: 'Manage Coupons'
        }
      },
      {
        path: 'buy',
        name: 'Buy',
        component: () => import(/* webpackChunkName: "buy" */ '../views/orderingProducts.vue'),
        meta: {
          title: 'Buy'
        }
      },
      {
        path: 'product/:prodID',
        name: 'ProductView',
        component: () => import(/* webpackChunkName: "productView" */ '../views/ProductView.vue'),
        props: true,
        meta: {
          title: 'Product'
        }
      },
      {
        path: 'activate/:token',
        name: 'activate',
        component: () => import(/* webpackChunkName: "activateAccount" */ '../views/activate.vue'),
        props: true,
        meta: {
          title: 'Account activation'
        }
      },
      {
        path: 'logsub',
        name: 'LogSignPage',
        component: () => import(/* webpackChunkName: "logSignPage" */ '../views/signLog.vue'),
        meta: {
          title: 'Login Sign'
        }
      },
      {
        path: 'clientOrders',
        name: 'ClientOrders',
        component: () => import(/* webpackChunkName: "clientOrders" */ '../views/clientOrders.vue'),
        meta: {
          title: 'Orders'
        }
      },
      {
        path: 'activation',
        name: 'Activation',
        component: () => import(/* webpackChunkName: "activationPage" */ '../views/activation.vue'),
        meta: {
          title: 'Account activation'
        }
      },
      {
        path: 'manageProducts',
        name: 'ManageProducts',
        component: () => import(/* webpackChunkName: "manageProducts" */ '../views/manageProducts.vue'),
        meta: {
          title: 'Manage Products'
        }
      },
      {
        path: 'editProduct',
        name: 'EditProduct',
        component: () => import(/* webpackChunkName: "editProduct" */ '../views/EditProduct.vue'),
        meta: {
          title: 'Edit Product'
        }
      },
      {
        path: 'news',
        name: 'NewsPage',
        component: () => import(/* webpackChunkName: "newsPage" */ '../views/news.vue'),
        meta: {
          title: 'News'
        }
      },
      {
        path: 'orders',
        name: 'OrdersPage',
        component: () => import(/* webpackChunkName: "ordersPage" */ '../views/orders.vue'),
        meta: {
          title: 'Orders'
        }
      },
      {
        path: 'product',
        name: 'ProductPage',
        component: () => import(/* webpackChunkName: "productPage" */ '../views/product.vue'),
        meta: {
          title: 'Product'
        }
      },
      {
        path: 'manageBrands',
        name: 'ProductBrands',
        component: () => import(/* webpackChunkName: "productBrands" */ '../views/productBrands.vue'),
        meta: {
          title: 'Brands'
        }
      },
      {
        path: 'manageTypes',
        name: 'ProductTypes',
        component: () => import(/* webpackChunkName: "productTypes" */ '../views/productTypes.vue'),
        meta: {
          title: 'Account activation'
        }
      },
      {
        path: 'profile',
        name: 'ProfilePage',
        component: () => import(/* webpackChunkName: "profilePage" */ '../views/profile.vue'),
        meta: {
          title: 'Account activation'
        }
      },
      {
        path: 'search',
        name: 'SearchPage',
        component: SearchPage,
        meta: {
          title: 'Account activation'
        }
      },
      {
        path: 'security',
        name: 'SecurityPage',
        component: () => import(/* webpackChunkName: "securityPage" */ '../views/security.vue'),
        meta: {
          title: 'Account activation'
        }
      },
      {
        path: 'manageSellers',
        name: 'SellersPage',
        component: () => import(/* webpackChunkName: "sellersPage" */ '../views/sellers.vue'),
        meta: {
          title: 'Account activation'
        }
      },
      {
        path: 'stats',
        name: 'StatsPage',
        component: () => import(/* webpackChunkName: "statsPage" */ '../views/StatsView.vue'),
        meta: {
          title: 'Account activation'
        }
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Store';
  next();
});

export default router